.profile-details {
  text-align: center !important;
  border: 1px solid #e7dede;
  border-radius: 10px;
  padding: 20px 30px;
  background: white;
  color: black;
  box-shadow: 2px 2px 8px #93959766;
}

.profile-details img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border: 2px solid #8fbf00;
}

.profile-details h3 {
  color: #8fbf00;
  margin-top: 10px;
}

.profile-desc {
  border: 1px solid #e7dede;
  border-radius: 10px;
  padding: 20px 30px;
  background: white;
  color: black;
  box-shadow: 2px 2px 8px #93959766;
}

.profile-desc h4 {
  border-bottom: 1px solid #8fbf00;
  padding-top: 10px;
  margin-bottom: 20px;
  /* color: #8fbf00; */
}

.profile-details button {
  background-color: #779e03;
  color: white;
  border: none;
  outline: none;
  padding: 8px 15px;
  border-radius: 30px;
}

.profile-details button:hover {
  background-color: black;
}

/* .rcw-close-widget-container {
  display: block !important;
} */

/* .rcw-chat-container {
  opacity: 1 !important;
} */

.rcw-conversation-container .rcw-header {
  /* background-color: #8fbf00 !important; */
  background-color: #53bef2 !important;
}
.rcw-client .rcw-message-text {
  background-color: #8fbf00a8 !important;
}
.rcw-launcher {
  /* background-color: #8fbf00 !important; */
  background-color: #53bef2 !important;
}

.rcw-conversation-container .avatar {
  height: 70px !important;
  width: 150px !important;
  object-fit: contain !important;
  border-radius: 0px !important;
  margin-right: 0px !important;
  vertical-align: middle;
}

/* .rcw-conversation-container > .rcw-header {
  background-color: red;
}

.rcw-message > .rcw-response {
  background-color: black;
  color: white;
} */
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .profile-details img {
    height: 200px;
  }
  .ipad-class {
    height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  .profile-details img {
    height: 350px;
  }
}
