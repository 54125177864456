.banner-image {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover !important;
  background-position: 50% 85% !important;
}

.banner {
  position: absolute;
  top: 52%;
  right: 50%;
  transform: translate(50%, -50%);
  /* background: #0c0b0b61; */
  /* background: #b7b0b061; */
  color: white;
}

.banner-Logo {
  width: 100%;
  height: 300px;
  object-fit: contain;
  margin-bottom: 10px;
}

.banner-title {
  padding: 30px;
  text-align: center;
}

.banner-title h1 {
  font-weight: 800;
  font-size: 60px;
}

.banner-title button {
  text-align: center;
  background: #8fbf00;
  font-size: 20px;
  padding: 7px 40px;
  border-radius: 20px;
  color: white;
  outline: none;
  border: none;
}

.banner-title button:hover {
  background-color: black;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .banner-image {
    height: 100vh !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 501px) {
  .banner-image {
    height: 100vh !important;
  }
}

@media screen and (max-width: 500px) and (min-width: 300px) {
  .banner-image {
    height: 100vh !important;
  }
  .banner-Logo {
    width: 330px;
    height: 200px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  .banner {
    top: 60%;
  }
}
@media screen and (max-width: 299px) {
  .banner-image {
    height: 100vh !important;
  }
  .banner {
    top: 60%;
  }
  .banner-Logo {
    width: 235px;
    height: 145px;
    object-fit: cover;
    margin-bottom: 10px;
  }
}
