.submit-button {
  border: none;
  outline: none;
  background-color: #8fbf00;
  color: white;
  padding: 8px 15px;
  border-radius: 10px;
}

.bg-image {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: 50% 85% !important;
}
.submit-button:hover {
  background-color: black;
}

.container-bg {
  background-color: white;
  opacity: 0.9;
  /* position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%); */
  padding: 30px 20px;
}

.rmdp-container {
  height: 100% !important;
  width: 100% !important;
}

.rmdp-container .rmdp-input {
  height: 100% !important;
  width: 100% !important;
}

.react-tagsinput-input {
  width: 100% !important;
}
.react-tagsinput--focused {
  border-color: none !important;
}
.react-tagsinput-tag {
  background-color: #1976d2 !important;
  border: 1px solid white !important;
  color: white !important;
}
.react-tagsinput-remove {
  color: white !important;
}
.test-border {
  border-color: #1976d2 !important;
}

@media screen and (max-width: 786px) {
  .rmdp-container {
    height: 55px !important;
  }
}
