.profile-cards {
  /* text-align: center !important; */
  border: 1px solid #e7dede;
  border-radius: 10px;
  padding: 15px;
  background: white;
  color: black;
  box-shadow: 2px 2px 8px #93959766;
}
.cards-image {
  border-radius: 50px;
}
.cards-image img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50px;
  border: 2px solid #8fbf00;
}

.profile-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-cards h5 {
  color: #779e03;
  margin-bottom: 3px;
}
.profile-status p {
  font-weight: 500;
  margin-bottom: 5px;
}

.profile-title {
  text-align: justify;
  font-size: 14px;
  font-weight: 500;
}
.profile-title p {
  margin-bottom: 5px;
}
.profile-subtitle {
  text-align: justify;
  font-weight: 500;
  font-size: 14px;
  color: #779e03;
  /* word-break: break-word; */
}
.profile-subtitle p {
  margin-bottom: 5px;
}
.profile-subtitle li {
  list-style-type: square;
}
.purpose-border {
  border-top: 1px solid #779e03;
  margin-top: 5px;
  padding-top: 5px;
  margin-left: 12px;
  width: 92%;
}
/* ::-webkit-scrollbar {
  display: none;
} */

.contact-btn {
  background-color: #779e03;
  color: white;
  border: none;
  outline: none;
  padding: 8px 15px;
  border-radius: 30px;
}
.contact-btn:hover {
  background-color: black;
}
.profile-cards button {
  background-color: #779e03;
  color: white;
  border: none;
  outline: none;
  padding: 8px 15px;
  border-radius: 30px;
}

.profile-cards button:hover {
  background-color: black;
}

@media screen and (max-width: 1024px) and (min-width: 401px) {
  .profile-title {
    font-size: 13px;
    margin-bottom: 3px;
  }
  .profile-subtitle {
    font-size: 13px;
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 400px) and (min-width: 301px) {
  .profile-cards h5 {
    margin-bottom: 3px;
  }

  .cards-image img {
    width: 90px;
    height: 90px;
  }
  .profile-title {
    font-size: 12px;
    margin-bottom: 3px;
  }
  .profile-subtitle {
    font-size: 12px;
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 300px) {
  .cards-image img {
    width: 70px;
    height: 70px;
  }
  .profile-title {
    font-size: 10px;
    margin-bottom: 3px;
  }
  .profile-subtitle {
    font-size: 10px;
    margin-bottom: 3px;
  }
}
