.save-button {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.save-button button {
  background-color: #8fbf00;
  outline: none;
  border: none;
  padding: 8px 20px;
  border-radius: 10px;
  color: white;
}

.save-button button:hover {
  background-color: black;
}

.about-text {
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
  width: 100%;
  height: 100px;
}

.profile-desc p {
  overflow-wrap: break-word;
}
